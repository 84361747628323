import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Stack } from '@tymate/margaret';
import { FaLinkedinIn } from 'react-icons/fa';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Zoom from 'react-medium-image-zoom';
import Slider from 'react-slick';
import { Container } from 'ui';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffectOnce } from 'react-use';

const LinkedinLink = styled.a`
  color: ${({ theme }) => theme.highlight};
`;

const ArrowButton = styled(Stack).attrs({ alignX: 'center', alignY: 'center' })`
  display: flex !important;
  width: 64px;
  height: 64px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 64px;
  z-index: 1;
  transition: opacity 150ms ease;

  &,
  &:hover {
    background: rgba(255, 255, 255, 0.6);
    color: ${({ theme }) => theme.highlight};
  }

  > svg {
    font-size: 32px;
    display: flex;
  }

  &:before {
    display: none;
  }

  &.slick-prev {
    left: ${({ theme }) => theme.spacing(-1)};
  }

  &.slick-next {
    right: ${({ theme }) => theme.spacing(-1)};
  }

  &.slick-disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  prevArrow: (
    <ArrowButton>
      <BsChevronLeft />
    </ArrowButton>
  ),
  nextArrow: (
    <ArrowButton>
      <BsChevronRight />
    </ArrowButton>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const LawyersList = ({ lawyers }) => {
  const [isInitialized, setIsInitialized] = useState();

  useEffectOnce(() => {
    setIsInitialized(true);
  });

  if (!isInitialized) {
    return null;
  }

  return (
    <Container>
      <Slider {...settings}>
        {lawyers.map(({ node }, id) => (
          <Stack
            direction="column"
            gutterSize={0.5}
            alignX="stretch"
            padding={1}
            key={id}
          >
            <div>
              <Zoom>
                <GatsbyImage image={node?.picture?.gatsbyImageData} />
              </Zoom>
            </div>

            <div
              css={`
                color: ${({ theme }) => theme.primary};
                font-weight: 700;
              `}
            >
              {node.name}
            </div>
            <div>{node.description}</div>

            {Boolean(node?.linkedinLink) && (
              <LinkedinLink
                href={node?.linkedinLink}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <FaLinkedinIn size={26} />
              </LinkedinLink>
            )}
          </Stack>
        ))}
      </Slider>
    </Container>
  );
};

export default LawyersList;
